import * as React from "react";
import { Form, IFields, required, isSameAsPassword } from "./Form";
import { Field } from "./Field";

interface Props {
    onConfirmPassword: (password: string) => void;
}

export const SetPasswordForm = (props:Props) => {
    const fields: IFields = {
        password: {
          id: "password",
          label: "Password",
          validation: { rule: required }
        },
        confirmpassword: {
          id: "confirmpassword",
          label: "Confirm password",
          validation: { rule: isSameAsPassword }
        }
      };
    return (
      <header className="password-header">
        <input type="text" className="input-text" hidden></input>
        <img className="userprofile-icon" height="26" width="26" alt="" src="http://accounts.getwoop.com/userprofile.png" />
        <label className="password-message">Set your new password.</label>

        <fieldset className="fieldset">
            <Form
                fields={fields}
                callback={props.onConfirmPassword}
                render={() => (
                <React.Fragment>
                    <div className="fields">
                    <Field {...fields.password} />
                    <Field {...fields.confirmpassword} />
                    </div>
                </React.Fragment>
                )}
            />
        </fieldset>        
      </header>
    );
  };