import React from 'react';
import { Auth } from 'aws-amplify';
import Amplify from '@aws-amplify/core';
import queryString from 'query-string';
import './App.css';
import { SetPasswordForm } from './SetPasswordForm';
import { ConfirmSignUp } from './ConfirmSignUp';

Amplify.configure({
  Auth: {
    region: 'eu-central-1',
    userPoolId: 'eu-central-1_g6Wn8ytjH',
    userPoolWebClientId: '74oon2v6eo9ivccc7d0qrarr3p',
  },
  Analytics: {
    disabled: true,
  },
});

export default class App extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    // because S3 redirect doesn't keep query parameters but adds them to the hash part
    // we use this to work for both "normal" query parameters and when s3 has redirected
    // away from them
    const qsPart = window.location.href.substr(window.location.href.indexOf('?'));
    const { id, code } = queryString.parse(qsPart);

    this.state = {
      setPasswordSuccess: false,
      confirmedSignUp: false,
      id,
      code,
      isSetPassword: window.location.href.includes('/passwordreset'),
      isVerifyEmail: window.location.href.includes('/verifyemail'),
      isConfirmUser: window.location.href.includes('/confirmuser'),
    };
  }

  componentDidMount = () => {
    if (this.state.isConfirmUser) {
      this.onConfirmSignUp();
    }
  };

  onConfirmSignUp = async () => {
    const { id, code } = this.state;
    if (id !== '' && code !== '') {
      try {
        await Auth.confirmSignUp(id, code);
        this.setState({ confirmedSignUp: true });
      } catch (err) {
        alert(err.message);
      }
    }
  };

  onConfirmPassword = async (password: string) => {
    const urlSplit = window.location.href.split('passwordreset?');

    if (urlSplit.length === 2) {
      const splitted = urlSplit[1].split('&');
      if (splitted.length === 2) {
        const username = splitted[0].replace('id=', '');
        const code = splitted[1].replace('code=', '');

        if (username !== '' && code !== '' && password !== '') {
          try {
            await Auth.forgotPasswordSubmit(username, code, password);
            this.setState({ setPasswordSuccess: true });
          } catch (err) {
            alert(err.message);
          }
        }
      }
    }
  };

  render() {
    return (
      <div className="app">
        <div className="top-bar">
          <img className="top-logo" height="38" width="116" alt="" src="http://accounts.getwoop.com/logo.png" />
        </div>
        {this.state.isSetPassword ? ( //
          this.state.setPasswordSuccess ? (
            <header className="password-header">
              <label className="password-message">Your password was successfully changed.</label>
            </header>
          ) : (
            <SetPasswordForm
              onConfirmPassword={(password) => {
                this.onConfirmPassword(password);
              }}
            />
          )
        ) : this.state.isConfirmUser ? (
          <header className="app-header">
            <ConfirmSignUp signUpConfirmed={this.state.confirmedSignUp} />
          </header>
        ) : this.state.isVerifyEmail ? (
          <header className="verification-header">
            <a
              href={`woopTrainingApp://?action=verifyemail&username=${this.state.id}&code=${this.state.code}`}
              className="open-woop-button"
            >
              Open Woop App
            </a>
          </header>
        ) : (
          <header className="password-header">
            <label className="password-message">Welcome to Woop</label>
          </header>
        )}
        <div className="bottom-bar">
          <img className="bottom-icon" height="20" width="25" alt="" src="http://accounts.getwoop.com/mini-logo.png" />
        </div>
      </div>
    );
  }
}
