import * as React from "react";
import { IErrors, IFormContext, FormContext, IValues } from "./Form";
 
/* The available editors for the field */
type Editor = "textbox" | "multilinetextbox" | "dropdown";
export interface IValidation {
    rule: (values: IValues, fieldName: string, args: any) => string;
    args?: any;
  }

export interface IFieldProps {
  /* The unique field name */
  id: string;
 
  /* The label text for the field */
  label?: string;
 
  /* The field value */
  value?: any;

  /* The field validator function and argument */
  validation?: IValidation;
}

export const Field: React.SFC<IFieldProps> = ({
  id,
  label,
  value
}) => {
    /*
    * Gets the validation error for the field
    * @param {IErrors} errors - All the errors from the form
    * @returns {string[]} - The validation error
    */
    const getError = (errors: IErrors): string => (errors ? errors[id] : "");
    const getEditorStyle = (errors: IErrors): any =>getError(errors) ? { borderColor: "red" } : {};
    /*
    const getStars = (currentPassword: string): string => {
        let stars = "";
        if(currentPassword) {
            for(let i = 0; i < currentPassword.length; i++) {
                stars += "*";
            }
        }
        return stars;
    };
*/

    const getVisibility = (currentPassword: string): boolean => {
      return (currentPassword === undefined || currentPassword.length <= 0);
    };

    return (
        <FormContext.Consumer>
          {(context: IFormContext | undefined) => (
            <div className="form-group">
    
              {context && (
                <input
                  autoComplete={"new-password"}
                  id={id}
                  type="password"
                  value={value}
                  style={getEditorStyle(context.errors)}
                  onChange={
                    (e: React.FormEvent<HTMLInputElement>) =>
                      context.setValues({ [id]: e.currentTarget.value }) 
                  }
                  onBlur={() => context.validate(id)}
                  className="form-control"
                />
               
              )}

{
  !context || getVisibility(context.getValues()[id]) ? <label className="password-placeholder">{label}</label> : ""
//<label id={id + "_stars"} className="stars">{context && getStars(context.getValues()[id])}</label>
}
              

              {context && getError(context.errors) && (
                <div className={"validation-error"}>
                    <p>{getError(context.errors)}</p>
                </div>
                )}
            </div>
          )}
        </FormContext.Consumer>
    );
};