import * as React from 'react';

interface Props {
  signUpConfirmed: boolean;
}

export const ConfirmSignUp = (props: Props) => {
  return (
    <div>
      {props.signUpConfirmed ? (
        <header className="verification-header">
          <img className="done-icon" height="26" width="26" alt="" src="http://accounts.getwoop.com/done.png" />
          <label className="verification-message">Done! You've successfully verified your account.</label>
          <a href="woopTrainingApp://" className="open-woop-button">
            Open Woop App
          </a>
        </header>
      ) : (
        <header className="verification-header">
          <label className="verification-message">The link has expired.</label>
          <a href="woopTrainingApp://" className="open-woop-button">
            Open Woop App
          </a>
        </header>
      )}
    </div>
  );
};
